import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDiscountCodeValue,
  setApplyDiscount,
  setApppliedCodesArr,
} from "../../redux/reducers/promoSlice";
import {
  handleGetAmount,
  handleGetDsAmount,
  handleGetIntroToDataAnalyticsAmountAmount,
  handleMonthlyAmount,
} from "../../redux/reducers/getAmountSlice";

// @components
import Buttons from "../../components/Buttons/Buttons";

// @icons
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSearchParams } from "react-router-dom";

const PromoCode = ({
  amount,
  monthly,
  bootcampType,
  discountArray,
}) => {
  const [showDiscountPerc, setShowDiscountPerc] = useState(0);
  const [searchParams] = useSearchParams();
  const bootCampType = searchParams.get("path");

  const dispatch = useDispatch();
  const codeApplied = useSelector(
    (state) => state.promoCode.codeApplied
  );
  const discountCode = useSelector(
    (state) => state.promoCode.discountCode
  );
  const apppliedCodesArr = useSelector(
    (state) => state.promoCode.apppliedCodesArr
  );

  const updateSingleDiscountCodeAmount = ({
    amount,
    promoCodeIndex,
  }) => {
    monthly
      ? dispatch(
          handleMonthlyAmount(
            amount * discountArray[promoCodeIndex]?.codePercentage
          )
        )
      : bootCampType === "data-science"
      ? dispatch(
          handleGetDsAmount(
            (
              amount * discountArray[promoCodeIndex]?.codePercentage
            )?.toFixed(2)
          )
        )
      : bootCampType === "intro-to-data-analytics"
      ? dispatch(
          handleGetIntroToDataAnalyticsAmountAmount(
            (
              amount * discountArray[promoCodeIndex]?.codePercentage
            )?.toFixed(2)
          )
        )
      : dispatch(
          handleGetAmount(
            (
              amount * discountArray[promoCodeIndex]?.codePercentage
            )?.toFixed(2)
          )
        );
  };

  const updateDoubleDiscountCodeAmount = () =>
    monthly
      ? dispatch(
          handleMonthlyAmount(
            amount *
              discountArray[discountCode === "BEF10" ? 1 : 2]
                ?.codePercentage
          )
        )
      : bootCampType === "data-science"
      ? dispatch(
          handleGetDsAmount(
            amount *
              discountArray[discountCode === "BEF10" ? 1 : 2]
                ?.codePercentage
          )
        )
      : dispatch(
          handleGetAmount(
            amount *
              discountArray[discountCode === "BEF10" ? 1 : 2]
                ?.codePercentage
          )
        );

  const applySingleDiscountCode = (promoCodeIndex) => {
    if (apppliedCodesArr.length > 0) {
      const ACTUAL_AMOUNT = 40;
      updateSingleDiscountCodeAmount({
        amount: ACTUAL_AMOUNT,
        promoCodeIndex,
      });
      dispatch(setApppliedCodesArr([]));
    } else updateSingleDiscountCodeAmount({ amount, promoCodeIndex });
    dispatch(setApplyDiscount(true));
    setShowDiscountPerc(
      discountArray[promoCodeIndex]?.codePercentage
    );
  };

  const applyDoubleDiscountCode = () => {
    const updatedAppliedCodes = [...apppliedCodesArr, discountCode];
    const sameCodeIsEnteredTwice =
      (discountCode === "BEF10" && apppliedCodesArr[0] === "BEF10") ||
      (discountCode === "DATA10" && apppliedCodesArr[0] === "DATA10");

    if (sameCodeIsEnteredTwice) {
      dispatch(setApplyDiscount(false));
    } else {
      dispatch(setApplyDiscount(true));
      updateDoubleDiscountCodeAmount();
      setShowDiscountPerc(
        updatedAppliedCodes.length === 1 &&
          updatedAppliedCodes[0] === "DATA10"
          ? 0.95
          : updatedAppliedCodes.length === 1 &&
            updatedAppliedCodes[0] === "BEF10"
          ? 0.9
          : 0.85
      );
      dispatch(setApppliedCodesArr(updatedAppliedCodes));
    }
    updatedAppliedCodes.length === 1 &&
      dispatch(setDiscountCodeValue(""));
  };

  const handleSetDiscountValue = (e) => {
    dispatch(setDiscountCodeValue(e.target.value));
  };

  const handleApplyDiscount = () => {
    // Special discount for those already enrolled in DA
    if (bootcampType?.dataScience && discountCode === "DSP60") {
      applySingleDiscountCode(2);
      return;
    }
    if (bootcampType?.dataAnalytics && discountCode === "DSP60") {
      dispatch(setApplyDiscount(false));
      return;
    }
    if (monthly && discountCode === "DSP60") {
      dispatch(setApplyDiscount(false));
      return;
    }

    // Special discount for those who sign up before 26th of April 2024
    // if (
    //   (discountCode === "BEF10" && !monthly) ||
    //   (discountCode === "DATA10" && !monthly)
    // ) {
    //   applyDoubleDiscountCode();
    // } else
    if (discountArray[0]?.code === discountCode) {
      applySingleDiscountCode(0);
    } else if (discountArray[1]?.code === discountCode) {
      applySingleDiscountCode(1);
    } else if (discountArray[2]?.code === discountCode) {
      applySingleDiscountCode(2);
    } else if (discountArray[3]?.code === discountCode) {
      applySingleDiscountCode(3);
    } else if (discountArray[4]?.code === discountCode) {
      applySingleDiscountCode(4);
    } else {
      dispatch(setApplyDiscount(false));
    }
  };

  const MAX_NUM_OF_APPLIED_CODE = 2;
  const disableApplyCodeButton = codeApplied === true;

  const twoCodeApplied =
    apppliedCodesArr.length === MAX_NUM_OF_APPLIED_CODE;

  const isDoubleDiscountCode =
    (apppliedCodesArr.length === 1 ||
      apppliedCodesArr.length === 2) &&
    codeApplied === true;

  const disableButton =
    apppliedCodesArr.length > 0
      ? isDoubleDiscountCode
      : disableApplyCodeButton;

  return (
    <>
      <div className="flex flex-col gap-3">
        <label
          className="xs:text-sm sm:text-base font-semibold"
          htmlFor="discount"
        >
          <span>Apply a discount code</span>
          {/* {!monthly && (
            <span className="text-xs block font-light">
              Extra 5% off with code{" "}
              <span className="text-[#FF8C00] font-medium underline">
                DATA10
              </span>{" "}
              if you enroll before{" "}
              <strong className="underline font-semibold">
                May 19th
              </strong>
            </span>
          )} */}
        </label>
        <div className="flex flex-row items-center gap-3 mb-3">
          <input
            className={`bg-[#efefef4d] px-4 py-3 xs:w-full xs:text-sm rounded border border-solid border-[#cccc] outline-none placeholder:text-sm placeholder:normal-case font-semibold`}
            type="text"
            name="discount"
            id="discount"
            placeholder="Enter discount code"
            value={discountCode}
            onChange={handleSetDiscountValue}
            autoComplete="off"
            disabled={
              apppliedCodesArr.length > 0
                ? twoCodeApplied
                : disableApplyCodeButton
            }
          />
          <div className="xs:w-full md:w-full">
            <Buttons
              type="button"
              onClick={handleApplyDiscount}
              disabled={
                apppliedCodesArr.length > 0
                  ? discountCode === "" || twoCodeApplied
                  : discountCode === "" || disableApplyCodeButton
              }
              style={`text-sm px-3 leading-loose bg-[#2b2c54] disabled:text-[#111] disabled:bg-[#f1f3f4] text-white w-full`}
            >
              Apply discount
            </Buttons>
          </div>
        </div>
      </div>
      {codeApplied === false ? (
        <span className="text-xs mt-3 w-fit font-bold border border-red-200 px-2 py-1 bg-red-200 text-red-600 rounded flex flex-row items-center gap-1">
          <CancelRoundedIcon
            fontSize="small"
            sx={{
              background: "red",
              color: "white",
              borderRadius: "50%",
            }}
          />
          {apppliedCodesArr.length === 1
            ? "Discount code already applied"
            : "The Discount code applied does not work."}
        </span>
      ) : (
        disableButton &&
        showDiscountPerc > 0 && (
          <span className="text-xs mt-3 w-fit font-bold border border-green-200 px-2 py-1 bg-green-200 text-green-600 rounded flex flex-row items-center gap-1">
            <CheckCircleIcon
              fontSize="small"
              sx={{
                background: "green",
                color: "white",
                borderRadius: "50%",
              }}
            />
            Discount code applied! You have saved{" "}
            {((1 - showDiscountPerc) * 100)?.toFixed(0)}
            %!
          </span>
        )
      )}
    </>
  );
};

export default PromoCode;
