import { createSlice } from "@reduxjs/toolkit";
import { SplitDate } from "../../utils/WebsiteTimer/WebsiteTimer";

export const timerSlice = createSlice({
  name: "countdowntimer",
  initialState: {
    countdownDate: ((nextCohortDate) => {
      const { month, year, day } = SplitDate(nextCohortDate);

      return new Date(year, month, day).getTime();
    })("2024-11-4"),
    displayCohortDate: "",
  },
  reducers: {
    updateCountdownTimer: (state, action) => {
      state.countdownDate = new Date(action.payload).getTime();
    },
    updateDisplayCohortDate: (state, action) => {
      state.displayCohortDate = action.payload;
    },
  },
});

export const { updateCountdownTimer, updateDisplayCohortDate } =
  timerSlice.actions;

export default timerSlice.reducer;
