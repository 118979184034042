export const caseStudyPath = [
  {
    id: 1,
    title: `GDP Analysis`,
    path: `../../course/analysis-and-visualisation-of-gdp-in-powerbi-excel`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/power_bi_logo_new.png",
  },
  {
    id: 2,
    title: `Designing Heatmaps in Excel`,
    path: `../../course/designing-heatmaps-in-excel`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/excel_logo_new.png",
  },
  // {
  //   id: 3,
  //   title: `Data Science Fundamentals with Python`,
  //   path: `../../course/data-science-fundamental-with-python`,
  //   img: `https://storage.googleapis.com/resagratia-webapp/misc/python-logo-notext.svg`,
  // },
  // {
  //   id: 4,
  //   title: `Data Fundamentals with Google Sheets`,
  //   path: `../../course/data-analytics-fundamentals-with-google-sheets`,
  //   img: `https://storage.googleapis.com/resagratia-webapp/misc/sheets.svg`,
  // },
];

export const excelPath = [
  {
    id: 1,
    title: `Introduction to Excel`,
    path: `../../course/excel`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/excel_logo_new.png",
  },
  {
    id: 2,
    title: `Business Analysis with Excel`,
    path: `../../course/excel`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/excel_logo_new.png",
  },
  {
    id: 3,
    title: `Designing Heatmaps in Excel`,
    path: `../../course/designing-heatmaps-in-excel`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/excel_logo_new.png",
  },
  {
    id: 4,
    title: `Introduction To Modern Excel And Power Query`,
    path: `../../course/introduction-to-modern-excel-tables-and-power-query`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/excel_logo_new.png",
  },
];

export const googleSheetsPath = [
  {
    id: 1,
    title: `Introduction To Data Analytics with Google Sheets`,
    path: `../../course/data-analytics-fundamentals-with-google-sheets`,
    img: "https://storage.googleapis.com/resagratia-webapp/misc/sheets.svg",
  },
];

export const learningPath = [
  {
    id: 1,
    title: `Excel Analyst`,
    path: `/learn/learning-path/excel-analyst-path`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/excel_logo_new.png",
  },
  {
    id: 2,
    title: `Power Bi Analyst`,
    path: `/learn/learning-path/powerbi-analyst-path`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/power_bi_logo_new.png",
  },
  {
    id: 3,
    title: `Tableau Viz Analyst`,
    path: `/learn/learning-path/tableauviz-analyst-path`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/tableau_logo.png",
  },
  {
    id: 4,
    title: `SQL Analyst`,
    path: `/learn/learning-path/sql-analyst-path`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/Postgresql_elephant.svg.png",
  },
];

export const powerbiPath = [
  {
    id: 1,
    title: `Introduction To Data Analytics`,
    path: `../../course/powerbi`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/power_bi_logo_new.png",
  },
  {
    id: 2,
    title: `GDP Analysis in PowerBI`,
    path: `../../course/analysis-and-visualisation-of-gdp-in-powerbi-excel`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/power_bi_logo_new.png",
  },
  {
    id: 3,
    title: `Expenses Management Dashboard in Power BI`,
    path: `../../course/expenses-management-dashboard-in-power-bi`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/power_bi_logo_new.png",
  },
];

export const sqlPath = [
  {
    id: 1,
    title: `SQL Game of Thrones`,
    path: `../../course/sql-game-of-thrones`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/Postgresql_elephant.svg.png",
  },
  {
    id: 2,
    title: `Data Analysis With SQL`,
    path: `../../course/data-analysis-with-sql`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/Postgresql_elephant.svg.png",
  },
  {
    id: 3,
    title: `SQL For Data Science`,
    path: `../../course/sql-for-data-science`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/Postgresql_elephant.svg.png",
  },
];

export const tableauPath = [
  {
    id: 1,
    title: `Introduction to Tableau`,
    path: `../../course/tableau`,
    img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/tableau_logo.png",
  },
];

export const pythonPath = [
  {
    id: 1,
    title: `Introduction to Python Programming Language`,
    path: `../../course/introduction-to-python-programming`,
    img: `https://storage.googleapis.com/resagratia-webapp/misc/python-logo-notext.svg`,
  },
  {
    id: 2,
    title: `Data Science Fundamentals with Python`,
    path: `../../course/data-science-fundamental-with-python`,
    img: `https://storage.googleapis.com/resagratia-webapp/misc/python-logo-notext.svg`,
  },
];
