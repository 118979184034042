import { useEffect } from "react";
import { Link } from "react-router-dom";
import { analytics } from "../../firebase/firebase";
import { logEvent } from "firebase/analytics";

const Buttons = ({
  children,
  style,
  onClick,
  openNewTab,
  hasLinkProp,
  state,
  path,
  hasIcon,
  externalLink,
  type,
  disabled,
  newBootcampDesign,
  sectionName,
}) => {
  useEffect(() => {
    if (!sectionName) return;

    logEvent(analytics, sectionName, {
      category: "CTA_button_clicked",
    });
  }, [sectionName]);

  return (
    <>
      {hasLinkProp ? (
        externalLink ? (
          <a
            onClick={onClick && onClick}
            {...(openNewTab && { target: "_blank" })}
            href={path}
          >
            <button className={`${style} rounded`}>{children}</button>
          </a>
        ) : (
          <Link
            to={path}
            state={state && state}
            onClick={onClick && onClick}
            {...(openNewTab && { target: "_blank" })}
          >
            <button className={`${style} rounded`}>{children}</button>
          </Link>
        )
      ) : (
        <button
          onClick={onClick}
          type={type && type}
          disabled={disabled && disabled}
          className={`${style} ${
            newBootcampDesign ? "" : "py-2 rounded"
          }`}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default Buttons;
