import LearningPathStepper from '../../components/LearningPathStepper/LearningPathStepper';
import { MetaTags } from '../../components/MetaTags/MetaTags';
import useScrollToTop from '../../hooks/useScrollToTop';

const powerbiCoursePath = [
  {
    id: 1,
    title: `Introduction To Data Analytics with Power BI`,
    connector: true,
  },
  {
    id: 2,
    title: `Case-study project on Gross Domestic Product (GDP)`,
    connector: false,
  },
];

const PowerbiAnalystPath = () => {
  useScrollToTop();
  return (
    <>
      <MetaTags
        path={`learn/powerbi-analyst-path`}
        title={`Resagratia | PowerBi Analyst Path`}
        desc={
        `Learn to master Power BI as a data analyst`
        }
      />
      <LearningPathStepper
        id='powerbi__path'
        title=' Power BI Analyst Learning Path'
        data={powerbiCoursePath}
        exitLink='/course/powerbi'
      />
    </>
  );
};

export default PowerbiAnalystPath;
