import { useEffect, useRef, useState } from "react";
import { RightArrowIcon } from "../../screens/BootcampLandingPage/FAQ";
import { ScrollButtons } from "../../components/ScrollButtons/ScrollButtons";
import { useScrollBarHandler } from "../../hooks/useScrollBarHandler";

export const BootcampOffers = ({
  desc,
  bgColor,
  pathTitle,
  singleCard,
  pathOffers,
  courseTrack,
  courseLogos,
  bootcampPaths,
  TargetAudience,
  allRoundedCorners,
}) => {
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`${bgColor} p-[1.108875rem] ${
        !allRoundedCorners && !singleCard
          ? "rounded-br-2xl"
          : "rounded-lg"
      } rounded-bl-2xl pb-[1.7742rem] ${
        bgColor === "bg-[#fff]" ? "text-[#000]" : "text-white"
      } ${!singleCard ? "min-h-[35.49625rem]" : ""}  flex-1 ${
        bootcampPaths ? "!min-h-min" : ""
      }`}
    >
      {!singleCard && (
        <>
          <h3 className="text-[1.7742rem] mb-[0.665325rem] leading-[2.152326375rem] font-normal sectionTitle">
            {pathTitle}
          </h3>
          <p className="text-[0.9979875rem] leading-[1.21rem]">
            {bootcampPaths && windowWidth <= 980 && !isOpen ? (
              <>
                {desc.split(" ").splice(0, 20).join(" ")}{" "}
                <span className="block">...</span>
              </>
            ) : (
              desc
            )}
          </p>
        </>
      )}
      {bootcampPaths && (
        <div className="flex gap-2 items-center">
          <RightArrowIcon
            isOpen={isOpen}
            color="#414266"
            deg="rotate(90deg)"
            style="max-[980px]:block hidden"
            onClick={setIsOpen.bind(null, !isOpen)}
          />
          <button
            onClick={setIsOpen.bind(null, !isOpen)}
            className="text-black max-[980px]:block hidden mt-2 font-normal pb-1"
          >
            Show {isOpen ? "less" : "more"}
          </button>
        </div>
      )}
      <div
        ref={contentRef}
        style={{
          height: isOpen
            ? `${contentHeight}px`
            : windowWidth > 980 && bootcampPaths
            ? `${contentHeight}px`
            : !bootcampPaths
            ? `${contentHeight}px`
            : "0",
        }}
        className={`overflow-hidden ${
          bootcampPaths ? "min-[980px]:h-0" : ""
        } transition-all duration-300 ease-in`}
      >
        {!singleCard && courseLogos && (
          <ul className="flex items-center gap-3 my-[1.5rem]">
            {courseLogos.map((CourseIcon, index) =>
              CourseIcon.type ? (
                <img
                  alt="Icon"
                  src={CourseIcon.icon}
                  key={index + CourseIcon.type}
                />
              ) : (
                <CourseIcon.icon key={index} />
              )
            )}
          </ul>
        )}
        <ul className="flex flex-col gap-[0.8871rem] mt-[1.5rem]">
          {pathOffers.map((offer, index) => {
            return (
              <li
                key={offer}
                className="flex gap-[0.665325rem] items-center"
              >
                <CheckMark
                  color={`${
                    index === pathOffers.length - 1 &&
                    bgColor === "bg-[#fff]"
                      ? "#ff8c00"
                      : index === pathOffers.length - 1 &&
                        bgColor !== "bg-[#fff]"
                      ? "#fff"
                      : singleCard
                      ? "#e7ab10"
                      : "#2B2C54"
                  }`}
                />
                <span className="text-[0.95rem] leading-[1.06452rem]">
                  {offer}
                </span>
              </li>
            );
          })}
        </ul>
        {singleCard && (
          <ul className="flex items-center gap-3 my-[1.5rem]">
            {courseLogos.map((CourseIcon, index) =>
              CourseIcon.type ? (
                <img
                  alt="Icon"
                  src={CourseIcon.icon}
                  key={index + CourseIcon.type}
                />
              ) : (
                <CourseIcon.icon key={index} />
              )
            )}
          </ul>
        )}
        {TargetAudience && (
          <p className="text-[0.9979875rem] leading-[1.21rem] mt-4">
            {TargetAudience}
          </p>
        )}
        <CourseTrackTimeLine
          courseTrack={courseTrack}
          cardBg="bg-[#fff]"
        />
      </div>
    </div>
  );
};

const CourseTrackTimeLine = ({ courseTrack, cardBg }) => {
  const SCROLL_LENGTH = 150;
  const {
    ref,
    leftButtonDisabled,
    rightButtonDisabled,
    handleLeftForwardScroll,
    handleLeftBackwardScroll,
    disableOrEnableScrollButton,
  } = useScrollBarHandler(SCROLL_LENGTH);
  return (
    <>
      {courseTrack && (
        <div className="relative mt-8">
          <div className="min-[491px]:hidden">
            <ScrollButtons
              display
              top="top-[8rem]"
              leftButtonRight="right-[46px]"
              leftButtonDisabled={leftButtonDisabled}
              rightButtonDisabled={rightButtonDisabled}
              handleLeftForwardScroll={handleLeftForwardScroll}
              handleLeftBackwardScroll={handleLeftBackwardScroll}
            />
          </div>
          <ul
            ref={ref}
            id="scrollContainer"
            onScroll={disableOrEnableScrollButton}
            className={`flex ${
              cardBg === "bg-[#fff]" ? "text-black" : "text-white"
            } gap-2 justify-start overflow-x-auto scrollbar`}
          >
            {courseTrack.map((duration) => {
              return (
                <li
                  className="border rounded flex-1 p-3 min-w-[135px] max-w-[135px] min-h-[100px] max-h-[100px]"
                  key={duration.courseName}
                >
                  <div className="font-normal text-xs items-center gap-1 flex justify-between">
                    <span>{duration.duration}</span>
                    <div className="inline">
                      <img
                        src={duration.img}
                        alt="course"
                        className="w-[1.2rem] min-w-[1.2rem]"
                      />
                    </div>
                  </div>
                  <div className="text-center mt-6 font-normal text-xs">
                    {duration.courseName}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

const CheckMark = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.6291"
    height="18.6291"
    fill="none"
    viewBox="0 0 22 22"
    className="min-w-[18.6291px]"
  >
    <path
      fill={color}
      d="M20.174 8.64c-.353-.37-.719-.75-.857-1.085-.127-.307-.134-.815-.142-1.307-.014-.915-.03-1.952-.75-2.673-.72-.72-1.758-.736-2.673-.75-.492-.008-1-.015-1.307-.143-.333-.137-.715-.503-1.084-.856C12.714 1.204 11.979.5 11 .5c-.979 0-1.713.704-2.36 1.326-.37.353-.75.719-1.085.857-.305.127-.815.135-1.307.142-.915.014-1.952.03-2.673.75-.72.72-.731 1.758-.75 2.673-.008.492-.015 1-.143 1.307-.137.333-.503.715-.856 1.084C1.204 9.286.5 10.021.5 11c0 .979.704 1.713 1.326 2.36.353.37.719.75.857 1.085.127.307.135.815.142 1.307.014.915.03 1.952.75 2.673.72.72 1.758.736 2.673.75.492.008 1 .015 1.307.142.333.138.715.504 1.084.857.647.622 1.382 1.326 2.361 1.326.979 0 1.713-.704 2.36-1.326.37-.353.75-.719 1.085-.857.307-.127.815-.134 1.307-.142.915-.014 1.952-.03 2.673-.75.72-.72.736-1.758.75-2.673.008-.492.015-1 .142-1.307.138-.333.504-.715.857-1.084.622-.647 1.326-1.382 1.326-2.361 0-.979-.704-1.713-1.326-2.36zm-4.893.64l-5.25 5.25a.748.748 0 01-1.062 0l-2.25-2.25a.75.75 0 111.062-1.06L9.5 12.94l4.72-4.72a.751.751 0 011.06 1.06z"
    ></path>
  </svg>
);
